import { Link } from "react-router-dom";
import Button from "../../modules/components/Button";

export default function Error404Page() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold">404</h1>
      <p>Whoops, this page does not exist.</p>
      <Link to="/dashboard/statistics">
        <Button title="Back to Dashboard" variant="primary" className="mt-2" />
      </Link>
    </div>
  );
}
