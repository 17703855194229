import {
  DocumentTextIcon,
  HomeIcon,
  KeyIcon,
  MagnifyingGlassIcon,
  UserGroupIcon,
  Bars3Icon,
  ArrowLeftStartOnRectangleIcon,
  UserIcon,
  WindowIcon,
  QrCodeIcon,
  BeakerIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { SearchModal } from "../components/SearchModal";
import BarcodeScanner from "../components/BarcodeScanner";

export default function Navbar2() {
  const { getPermission } = useKindeAuth();
  //const [isSupportTorch, isOpen, onTorchSwitch] = useTorch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const menuItems = [
    { title: "Dashboard", to: "statistics", icon: HomeIcon, show: true },
    { title: "Bikes", to: "bikes", icon: KeyIcon, show: true },
    {
      title: "Reports",
      to: "reports",
      icon: DocumentTextIcon,
      show: true,
    },
    {
      title: "Users",
      to: "users",
      icon: UserGroupIcon,
      permission: "VIEW_USERS",
      show: true,
    },
    {
      title: "Logs",
      to: "logs",
      icon: WindowIcon,
      permission: "VIEW_LOGS",
      show: true,
    },
    {
      title: "Submissions",
      to: "submissions",
      icon: BeakerIcon,
      permission: "VIEW_SUBMISSIONS",
      show: true,
    },
    {
      title: "Profile",
      to: "profile",
      icon: UserIcon,
      show: true,
    },
  ];

  return (
    <Fragment>
      <SearchModal
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        onOpen={() => setIsSearchOpen(true)}
      />
      <div className="sm:hidden fixed top-5 left-3.5 z-40">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="p-2 text-white bg-customBlue rounded-lg"
        >
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>
      {/* Sidebar */}
      <aside
        id="default-sidebar"
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 h-screen bg-customBlue pt-safe-area transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
      >
        <div className="flex flex-col justify-between h-full overflow-y-auto py-5 px-3">
          <div>
            <img
              src="/swapfiets-logo.png"
              alt="swapfiets"
              width="40"
              height="40"
              className="mx-auto mb-2 z-50 rounded-lg"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
            <ul className="space-y-4">
              <li>
                <button
                  onClick={() => setIsSearchOpen(true)}
                  className="flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue"
                >
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </button>
              </li>
              {getPermission && getPermission("QR_SCANNER")?.isGranted ? (
                <li>
                  <button
                    onClick={() => {
                      setIsScannerOpen(!isScannerOpen);
                      setIsSidebarOpen(!isSidebarOpen);
                    }}
                    className="flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue"
                  >
                    <QrCodeIcon className="w-6 h-6" />
                  </button>
                </li>
              ) : null}
              {/* Map through dynamic menu items */}
              {menuItems
                .filter((l) => l.show)
                .map((item, i) =>
                  !item.permission ||
                  (item.permission &&
                    getPermission &&
                    getPermission(item.permission)?.isGranted) ? (
                    <li key={item.title}>
                      <NavLink
                        key={item.to + i}
                        to={`/dashboard/${item.to}`}
                        title={item.title}
                        className={({ isActive }) =>
                          `flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue ${
                            isActive ? "bg-customDarkBlue" : ""
                          }`
                        }
                      >
                        <item.icon className="w-6 h-6" />
                      </NavLink>
                    </li>
                  ) : null
                )}
            </ul>
          </div>
          <div>
            <NavLink
              key={"logout"}
              to={"/dashboard/logout"}
              title={"Logout"}
              className={
                "flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue"
              }
            >
              <ArrowLeftStartOnRectangleIcon className="w-6 h-6" />
            </NavLink>
            <p className="text-white italic text-xs">v. 1.3.3</p>
          </div>
        </div>
      </aside>
      {isScannerOpen &&
      getPermission &&
      getPermission("QR_SCANNER")?.isGranted ? (
        <BarcodeScanner
          isOpen={isScannerOpen}
          onClose={() => setIsScannerOpen(false)}
        />
      ) : null}
    </Fragment>
  );
}
