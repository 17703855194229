import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IUser } from "../../interfaces/user";
import { API_URL } from "../../../../constants";
import toast from "react-hot-toast";
import { useToken } from "./useToken";

export const useUsers = () => {
  const accessToken = useToken();

  const fetchUsers = async (): Promise<IUser[]> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const { data } = await axios.get<IUser[]>(API_URL + "/users", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error: any) {
      toast.error(error.response?.data || "Failed to fetch users");
      //console.error("Failed to fetch users:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
    select: (data) => data || [],
  });

  return { isLoading, users: data, error };
};

export default useUsers;
