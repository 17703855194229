import { IReport } from "./report";

export interface IBike {
  frameNumber: string;
  bikeType: BikeType;
  reports?: IReport[];
  color: string;
  trackerId?: string;
}

export enum BikeType {
  Select = -1,
  Original = 0,
  Deluxe7 = 1,
  Power1 = 2,
  Power7 = 3,
}

// BikeColor enum to store enum values for colors
export enum BikeColor {
  Red = "Red",
  Black = "Black",
  Yellow = "Yellow",
  OysterGrey = "Oyster Grey",
  PistacheGreen = "Pistache Green",
  MiccaBlue = "Micca Blue",
  LightOlive = "Light Olive",
  DarkBirch = "Dark Birch",
  HunterGreen = "Hunter Green",
  MatteBlackberryWine = "Matte Blackberry Wine",
  BlueGranite = "Blue Granite",
  BlackberryWine = "Blackberry Wine",
  SaltGrey = "Salt Grey",
  LagoonGreen = "Lagoon Green",
  MatteBlack = "Matte Black",
  EspressoBrown = "Espresso Brown",
  SundialBrown = "Sundial Brown",
  CinamonOrange = "Cinamon Orange",
  MineralGreen = "Mineral Green",
  HalvaGrey = "Halva Grey",
  BrickRed = "Brick Red",
  EclipseBlack = "Eclipse Black",
  PantharBlack = "Panthar Black",
}

// Mapping BikeColor to their hex values
export const BikeColorHexMap: { [key in BikeColor]: string } = {
  [BikeColor.Red]: "#BB1F11",
  [BikeColor.Black]: "#000000",
  [BikeColor.Yellow]: "#B5882F",

  [BikeColor.EclipseBlack]: "#404250",
  [BikeColor.OysterGrey]: "#363A3C",
  [BikeColor.PistacheGreen]: "#B6AC90",
  [BikeColor.MiccaBlue]: "#424E5E",
  [BikeColor.PantharBlack]: "#020202",
  [BikeColor.LightOlive]: "#595237",
  [BikeColor.DarkBirch]: "#AB8E75",

  [BikeColor.HunterGreen]: "#2C3513",
  [BikeColor.MatteBlackberryWine]: "#4B1D15",
  [BikeColor.BlueGranite]: "#3E3F3E",
  [BikeColor.BlackberryWine]: "#2A1717",
  [BikeColor.SaltGrey]: "#BFBFB6",
  [BikeColor.LagoonGreen]: "#0F2733",
  [BikeColor.MatteBlack]: "#010101",
  [BikeColor.SundialBrown]: "#2b1c12",
  [BikeColor.CinamonOrange]: "#6B0E0B",

  [BikeColor.EspressoBrown]: "#2f1f14",
  [BikeColor.MineralGreen]: "#616D5D",
  [BikeColor.HalvaGrey]: "#675D50",
  [BikeColor.BrickRed]: "#7C2719",
};

// Define colors for each bike type using the central color map
export const bikeColorOptions: {
  [key in BikeType]: { colorClass: string; value: BikeColor }[];
} = {
  [BikeType.Select]: [],
  [BikeType.Original]: [
    {
      colorClass: BikeColorHexMap[BikeColor.Red],
      value: BikeColor.Red,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.Black],
      value: BikeColor.Black,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.Yellow],
      value: BikeColor.Yellow,
    },
  ],
  [BikeType.Deluxe7]: [
    {
      colorClass: BikeColorHexMap[BikeColor.EclipseBlack],
      value: BikeColor.EclipseBlack,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.PantharBlack],
      value: BikeColor.PantharBlack,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.OysterGrey],
      value: BikeColor.OysterGrey,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.PistacheGreen],
      value: BikeColor.PistacheGreen,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.MiccaBlue],
      value: BikeColor.MiccaBlue,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.BlackberryWine],
      value: BikeColor.BlackberryWine,
    },
  ],
  [BikeType.Power1]: [
    {
      colorClass: BikeColorHexMap[BikeColor.HunterGreen],
      value: BikeColor.HunterGreen,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.MatteBlackberryWine],
      value: BikeColor.MatteBlackberryWine,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.BlueGranite],
      value: BikeColor.BlueGranite,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.BlackberryWine],
      value: BikeColor.BlackberryWine,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.SaltGrey],
      value: BikeColor.SaltGrey,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.LagoonGreen],
      value: BikeColor.LagoonGreen,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.MatteBlack],
      value: BikeColor.MatteBlack,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.SundialBrown],
      value: BikeColor.SundialBrown,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.CinamonOrange],
      value: BikeColor.CinamonOrange,
    },
  ],
  [BikeType.Power7]: [
    {
      colorClass: BikeColorHexMap[BikeColor.EspressoBrown],
      value: BikeColor.EspressoBrown,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.MineralGreen],
      value: BikeColor.MineralGreen,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.HalvaGrey],
      value: BikeColor.HalvaGrey,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.BrickRed],
      value: BikeColor.BrickRed,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.BlueGranite],
      value: BikeColor.BlueGranite,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.Black],
      value: BikeColor.Black,
    },
    {
      colorClass: BikeColorHexMap[BikeColor.PistacheGreen],
      value: BikeColor.PistacheGreen,
    },
  ],
};

// Function to map hex back to BikeColor
export const hexToBikeColorMap: { [key: string]: BikeColor } =
  Object.fromEntries(
    Object.entries(BikeColorHexMap).map(([color, hex]) => [
      hex,
      color as BikeColor,
    ])
  );
