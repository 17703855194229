import { Navigate, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { PencilIcon } from "@heroicons/react/24/solid";
import useReports from "../../modules/api/hooks/reports/useReports";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";
import ReportForm from "../../modules/forms/ReportForm";

export function ShowReportPage() {
  const { id } = useParams();
  const { reports } = useReports();
  const navigate = useNavigate();
  const report = reports?.find((report) => report.journalNumber === id);

  if (!report) {
    return <Navigate to="/dashboard/reports" />;
  }

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Report</p>
        <button
          className="btn-icon"
          onClick={() =>
            navigate("/dashboard/reports/edit/" + report?.journalNumber)
          }
        >
          <PencilIcon className="h-5 w-5 text-gray-400" />
        </button>
      </div>
      <ReportForm reportData={report} readOnly />
      <div className="text-gray-400 text-xs mt-1">
        Last updated on{" "}
        {format(new Date(report?.updatedAt), "MMM dd, yyyy 'at' HH:mm")}
        {" by "}
        <p className="inline-flex text-xs">{report?.updatedBy || "Unknown"}</p>
      </div>
    </div>
  );
}
