import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../../../../constants";
import { IBike } from "../../interfaces/bike";
import { useToken } from "../misc/useToken";

interface IUseDeleteBikeProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useDeleteBike = (props?: IUseDeleteBikeProps) => {
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const deleteBikeByFrameNumber = async (frameNumber: string) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    return await axios.delete(API_URL + `/bikes/${frameNumber}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationKey: ["delete", "bike"],
    mutationFn: (bike: IBike) => deleteBikeByFrameNumber(bike.frameNumber),
    onError: (error: Error) => {
      console.error(error);
      props?.onError?.(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["bikes"] });
      props?.onSuccess?.();
    },
  });
};
