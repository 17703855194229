import React, { useEffect, useState } from "react";
import { useController, Control } from "react-hook-form";
import { bikeColorOptions, BikeType } from "../api/interfaces/bike";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";

interface BikeColorDropdownProps {
  name: string;
  bikeType: BikeType;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
}

const BikeColorDropdown: React.FC<BikeColorDropdownProps> = ({
  name,
  bikeType,
  control,
  label = "Color",
  disabled = false,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control });

  const [selectedColorName, setSelectedColorName] = useState<string | null>(
    null
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Update selectedColorName based on the current value when it changes or in readonly mode
  useEffect(() => {
    const selectedColor = bikeColorOptions[bikeType]?.find(
      (colorOption) => colorOption.colorClass === value
    );
    setSelectedColorName(selectedColor ? selectedColor.value : null);
  }, [value, bikeType]);

  // Reset color selection when bikeType changes only if not in readonly mode
  useEffect(() => {
    if (!disabled) {
      setSelectedColorName(null);
      onChange(""); // Reset the color value in the form state
      setIsOpen(false);
    }
  }, [bikeType, onChange, disabled]);

  const handleColorSelect = (colorName: string, colorClass: string) => {
    setSelectedColorName(colorName);
    setIsOpen(false);
    onChange(colorClass); // Update form with the color hex value
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent form submission
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="relative w-full">
      {label && (
        <p className="block mb-2 text-sm font-medium text-gray-900">{label}</p>
      )}
      <button
        onClick={toggleDropdown}
        className={`disabled:opacity-100 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full text-left p-2.5 ${
          error ? "border-red-500" : ""
        }`}
        disabled={disabled}
      >
        {value && (
          <span
            className="w-4 h-4 rounded-full mr-2 float-left mt-0.5"
            style={{ backgroundColor: value }}
          ></span>
        )}
        <span
          className={`${selectedColorName ? "text-gray-900" : "text-gray-400"}`}
        >
          {selectedColorName ? selectedColorName : "Select a color"}
        </span>
        <ChevronUpDownIcon className="w-5 h-5 float-right" />
      </button>

      {/* Collapsible Dropdown Menu */}
      {isOpen && !disabled && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg">
          {bikeColorOptions[bikeType]?.map((colorOption, index) => (
            <li
              key={index}
              className="flex items-center px-4 py-2 cursor-pointer hover:rounded-lg hover:bg-gray-100 text-sm"
              onClick={() =>
                handleColorSelect(colorOption.value, colorOption.colorClass)
              }
            >
              <span
                className="w-4 h-4 rounded-full mr-2"
                style={{ backgroundColor: colorOption.colorClass }}
              ></span>
              {colorOption.value}
            </li>
          ))}
        </ul>
      )}
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
    </div>
  );
};

export default BikeColorDropdown;
