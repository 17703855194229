import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { IBike } from "../../interfaces/bike";
import { API_URL } from "../../../../constants";
import { useToken } from "../misc/useToken";

export const useBikes = () => {
  const accessToken = useToken();

  const fetchBikes = async (): Promise<IBike[]> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const { data } = await axios.get<IBike[]>(API_URL + "/bikes", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error: any) {
      //console.error("Failed to fetch bikes:", error);
      toast.error(error.response?.data || "Failed to fetch bikes");
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["bikes"],
    queryFn: fetchBikes,
    select: (data) => data || [],
  });

  return { isLoading, bikes: data, error };
};

export default useBikes;
