import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormSelect from "../components/form/FormSelect";
import Button from "../components/Button";
import FormInput from "../components/form/FormInput";
import { SubmissionType } from "../api/interfaces/submissions";
import FormTextArea from "../components/form/FormTextArea";

export interface ISubmissionForm {
  fullName: string;
  email: string;
  title: string;
  type: SubmissionType;
  description: string;
}

// Validation schema using Yup
const submissionSchema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),
  email: yup.string().required("Email is required"),
  type: yup
    .number()
    .oneOf(
      Object.values(SubmissionType).filter(
        (value) => typeof value === "number" && value !== SubmissionType.Select
      ) as number[],
      "Select a submission type"
    )
    .required("Submission type is required"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
});

interface SubmissionFormProps {
  submissionData?: ISubmissionForm;
  readOnly?: boolean;
  buttonPlaceholder?: string;
  onSubmit?: (data: ISubmissionForm) => void;
}

const SubmissionForm: React.FC<SubmissionFormProps> = ({
  submissionData,
  readOnly = false,
  buttonPlaceholder = "Submit",
  onSubmit = () => {},
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ISubmissionForm>({
    defaultValues: submissionData || {
      fullName: "",
      email: "",
      type: undefined,
      title: "",
      description: "",
    },
    resolver: yupResolver(submissionSchema),
  });

  const submissionTypeOptions = Object.entries(SubmissionType)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ label: key, value }));

  return (
    <form>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 mt-4">
        <FormInput
          type="text"
          name="fullName"
          control={control}
          label="Full name"
          disabled={control._formValues.fullName || readOnly}
          placeholder="Full name"
        />
        <FormInput
          type="text"
          name="email"
          control={control}
          label="Email"
          disabled={control._formValues.email || readOnly}
          placeholder="Email"
        />
        <FormInput
          type="text"
          name="title"
          control={control}
          label="Title"
          disabled={readOnly}
          placeholder="Title"
        />
        <FormSelect
          name="type"
          control={control}
          label="Type"
          options={submissionTypeOptions}
          disabled={readOnly}
        />
        <div className="col-span-1 sm:col-span-2">
          <FormTextArea
            name="description"
            control={control}
            label="Summary"
            disabled={readOnly}
            placeholder="Write the details of the feature or bug here."
          />
        </div>
      </div>

      {!readOnly && (
        <Button
          onClick={handleSubmit(onSubmit)}
          title={buttonPlaceholder}
          variant="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          className="mt-4 w-full"
        />
      )}
    </form>
  );
};

export default SubmissionForm;
