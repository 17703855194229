import { Navigate, useParams } from "react-router-dom";
import InputField from "../../modules/components/form/InputField";
import useUsers from "../../modules/api/hooks/misc/useUsers";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";

export function ShowUserPage() {
  const { id } = useParams();
  const { users } = useUsers();
  const user = users?.find((user) => user.userId === id);

  if (!user) {
    return <Navigate to="/dashboard/users" />;
  }

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">User</p>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mt-4">
        <InputField
          label="ID"
          name="id"
          type="text"
          value={user?.userId}
          onChange={() => {}}
          readonly
        />
        <InputField
          label="Name"
          name="name"
          type="text"
          value={user?.name}
          onChange={() => {}}
          readonly
        />
        <InputField
          label="Email"
          name="email"
          type="text"
          value={user?.email}
          onChange={() => {}}
          readonly
          className="break-all"
        />
        <InputField
          label="Suspended?"
          name="suspended"
          type="text"
          value={user?.isSuspended ? "Yes" : "No"}
          onChange={() => {}}
          readonly
          className="break-all"
        />
      </div>
    </div>
  );
}
