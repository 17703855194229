import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Link } from "react-router-dom";

interface TableActionsProps<T> {
  item: T;
  deleteMessageFn: (item: T) => string;
  deleteFn: (item: T) => void;
  idAccessor: (item: T) => string;
  editPermission?: string;
  deletePermission?: string;
  hideViewButton?: boolean;
}

const TableActions = <T,>({
  item,
  deleteMessageFn,
  deleteFn,
  idAccessor,
  editPermission,
  deletePermission,
  hideViewButton,
}: TableActionsProps<T>) => {
  const { getPermission } = useKindeAuth();
  const handleDelete = () => {
    if (window.confirm(deleteMessageFn(item))) {
      deleteFn(item);
    }
  };

  return (
    <div className="space-x-0 sm:space-x-1.5 space-y-1.5 sm:space-y-0">
      {!hideViewButton && (
        <Link className="btn-icon" to={`${idAccessor(item)}`}>
          <EyeIcon className="h-5 w-5" />
        </Link>
      )}

      {editPermission &&
      getPermission &&
      getPermission(editPermission)?.isGranted ? (
        <Link className="btn-icon" to={`./edit/${idAccessor(item)}`}>
          <PencilIcon className="h-5 w-5" />
        </Link>
      ) : null}

      {deletePermission &&
      getPermission &&
      getPermission(deletePermission)?.isGranted ? (
        <button className="btn-icon" onClick={handleDelete}>
          <TrashIcon className="h-5 w-5" />
        </button>
      ) : null}
    </div>
  );
};

export default TableActions;
