import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../../constants";
import { SubmissionType } from "../../interfaces/submissions";
import { useToken } from "../misc/useToken";
import { ISubmissionForm } from "../../../forms/SubmissionForm";

interface IUseSubmissionProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useRequestFeature = (props?: IUseSubmissionProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const requestFeature = async (
    fullName: string | null,
    email: string | null,
    title: string,
    type: SubmissionType,
    description: string
  ) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    return await axios.post(
      API_URL + "/submissions",
      {
        fullName: fullName || "Unknown",
        email: email || "Unknown",
        title: title,
        type: type,
        description: description,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["post", "feature"],
    mutationFn: (feature: ISubmissionForm) =>
      requestFeature(
        feature.fullName,
        feature.email,
        feature.title,
        feature.type,
        feature.description
      ),
    onError: (error: any) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error.response?.data || "An error occurred");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["feature"] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate("/dashboard/statistics");
      window.scrollTo(0, 0);
    },
  });
};
