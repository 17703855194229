import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Table from "../../modules/components/table/Table";
import { useEffect, useState } from "react";
import { ILoggingModule } from "../../modules/api/interfaces/loggingModule";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import SearchComponent from "../../modules/components/SearchComponent";
import useLogs from "../../modules/api/hooks/misc/useLogs";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";

export function LogsPage() {
  const { logs, isLoading } = useLogs();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState(logs);
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission("VIEW_LOGS")?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  const columns = [
    {
      label: "Time",
      accessor: (row: ILoggingModule) => row.timestamp,
      render: (date: Date) => {
        return format(new Date(date), "dd-MM-yyyy HH:mm", {
          locale: da,
        });
      },
    },
    {
      label: "Method",
      accessor: (row: ILoggingModule) => row.method,
      classNameCell: "text-center sm:text-left px-4 lg:px-0",
      classNameHeader: "text-center sm:text-left px-4 lg:px-0",
    },
    {
      label: "Endpoint",
      accessor: (row: ILoggingModule) => row.url,
      render: (url: string) => <span>{url.split("/api")[1]}</span>,
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },

    {
      label: "Status",
      accessor: (row: ILoggingModule) => row.statusCode,
      render: (statusCode: number) => {
        let bgColor = "bg-gray-500";

        if (statusCode >= 200 && statusCode < 300) {
          bgColor = "bg-green-500";
        } else if (statusCode >= 300 && statusCode < 400) {
          bgColor = "bg-blue-500";
        } else if (statusCode >= 400 && statusCode < 500) {
          bgColor = "bg-orange-500";
        } else if (statusCode >= 500) {
          bgColor = "bg-red-500";
        }

        return (
          <span className={`px-2 py-1 rounded-full text-white ${bgColor}`}>
            {statusCode}
          </span>
        );
      },
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
    {
      label: "User",
      accessor: (row: ILoggingModule) => row.user,
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
  ];

  useEffect(() => {
    if (logs) {
      setSearchData(logs);
    }
  }, [logs]);

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <p className="text-2xl tracking-wide font-medium h-10">Logs</p>
      </div>
      <div className="flex flex-row mt-4 gap-4">
        <SearchComponent
          placeholder="Search by request ID..."
          data={logs || []}
          inputMode="search"
          filterAttribute="requestId"
          onChange={(data) => {
            setSearchData(data);
          }}
        />
      </div>
      <Table
        data={searchData || []}
        columns={columns}
        tableActions={{
          deleteFn: (item) => console.log("Deleting", item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.requestId}?`,
        }}
        idAccessor={(row) => row.requestId}
        isLoading={isLoading}
        emptyMessage="No logs found"
      />
    </div>
  );
}
