import { Navigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";
import useSubmissions from "../../modules/api/hooks/submissions/useSubmissions";
import SubmissionForm from "../../modules/forms/SubmissionForm";
import { Fragment } from "react/jsx-runtime";

export function ShowSubmissionPage() {
  const { id } = useParams();
  const { submissions } = useSubmissions();
  const submission = submissions?.find(
    (submission) => submission.submissionId.toString() === id
  );

  if (!submission) {
    return <Navigate to="/dashboard/statistics" />;
  }

  return (
    <Fragment>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <Breadcrumbs />
        <div className="flex items-center justify-between">
          <p className="text-2xl tracking-wide font-medium">Submission</p>
        </div>
        <SubmissionForm readOnly submissionData={submission} />
      </div>
    </Fragment>
  );
}
