import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";
import { SubmissionType } from "../../modules/api/interfaces/submissions";
import { useRequestFeature } from "../../modules/api/hooks/submissions/useCreateSubmission";
import SubmissionForm, {
  ISubmissionForm,
} from "../../modules/forms/SubmissionForm";

export default function NewSubmissionPage() {
  const { getUser } = useKindeAuth();
  const user = getUser?.();
  const userName = user ? `${user.given_name} ${user.family_name}` : "";
  const userEmail = user?.email || "";
  const [error, setError] = useState("");

  const createFeatureMutation = useRequestFeature({
    onError: (error) => {
      setError(error);
    },
  });
  const initialSubmissionData: ISubmissionForm = {
    fullName: userName,
    email: userEmail,
    title: "",
    type: SubmissionType.Select,
    description: "",
  };

  const handleSubmit = async (data: ISubmissionForm) => {
    const formWithMappedType =
      data.type === SubmissionType.Feature ? "feature" : "bug";
    toast.promise(createFeatureMutation.mutateAsync(data), {
      loading: `Submitting ${formWithMappedType}...`,
      success: "Submission sent successfully!",
      error: "Error sending submission.",
    });
  };

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-lg tracking-wide font-medium sm:text-2xl h-10">
          Request a feature or report a bug
        </p>
      </div>
      <div className="grid gap-4 sm:gap-6">
        <SubmissionForm
          submissionData={initialSubmissionData}
          onSubmit={(data) => {
            handleSubmit(data);
          }}
        />
      </div>
      {error && (
        <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
      )}
    </div>
  );
}
