import {
  //BeakerIcon,
  UserIcon,
  //UsersIcon,
  //WindowIcon,
} from "@heroicons/react/24/solid";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";
//import { useNavigate } from "react-router-dom";

export default function ProfilePage() {
  const { getUser, getPermissions, isLoading } = useKindeAuth();
  //const navigate = useNavigate();

  const user = getUser?.();
  const permissions = getPermissions?.()?.permissions;
  /*const claims = getClaim?.("roles")?.value;
  const isAdmin =
    Array.isArray(claims) && claims.some((claim: any) => claim.key === "ADMIN");*/

  const userName = user ? `${user.given_name} ${user.family_name}` : null;
  const userEmail = user?.email;
  const userId = user?.id;

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <p className="text-2xl tracking-wide font-medium h-10">Profile</p>
      </div>
      <div className="flex flex-row mt-4 gap-4">
        {isLoading || !user ? (
          <div>
            <div className="items-center space-y-3">
              <div className="w-32 h-32 rounded-full mb-4 overflow-hidden bg-gray-100 animate-pulse" />
              <p className="bg-gray-100 h-4 rounded animate-pulse w-56"></p>
              <p className="bg-gray-100 h-4 rounded animate-pulse w-40"></p>
            </div>
            <p className="bg-gray-100 h-4 mt-6 rounded animate-pulse w-52"></p>
            <div className="mt-8">
              <h2 className="text font-semibold text-gray-800">Permissions</h2>
              <ul className="space-y-4 mt-1">
                {Array.from({ length: 4 }).map((_, index) => (
                  <li
                    key={index}
                    className="bg-gray-100 h-4 rounded animate-pulse w-32"
                  />
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <div className="items-center">
              <div className="w-28 h-28 rounded-full border-4 border-gray-300 mb-4 overflow-hidden flex items-center justify-center">
                <UserIcon className="w-24 h-24 mt-10 text-gray-300" />
              </div>

              <h2 className="text-lg text-gray-800">{userName || "Unknown"}</h2>
              <p className="text-gray-500">{userEmail}</p>
              <p className="text-xs mt-4">{userId}</p>
            </div>
            <div className="mt-8">
              <h2 className="text-base font-semibold text-gray-800">
                Permissions
              </h2>
              <ul className="list-disc list-inside ml-2 mt-1 space-y-1">
                {permissions && permissions.length > 0 ? (
                  permissions.map((permission: any, index: number) => (
                    <li key={index} className="text-sm text-gray-800">
                      {permission}
                    </li>
                  ))
                ) : (
                  <div className="-ml-2">No extra permissions available</div>
                )}
              </ul>
            </div>
            {/*<div className="mt-8">
              <h2 className="text-base font-semibold text-gray-800">
                Admin Apps
              </h2>
              {!isAdmin ? (
                <div className="">No admin apps available</div>
              ) : null}
              <div className="flex gap-4 mt-1">
                {getPermission && getPermission("VIEW_USERS")?.isGranted ? (
                  <button
                    title="Users"
                    className={
                      "items-center bg-customBlue justify-center p-2.5 text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue hover:bg-customDarkBlue"
                    }
                    onClick={() => navigate("/dashboard/users")}
                  >
                    <UsersIcon className="w-6 h-6" />
                  </button>
                ) : null}
                {getPermission && getPermission("VIEW_LOGS")?.isGranted ? (
                  <button
                    title="Logs"
                    className={
                      "items-center bg-customBlue justify-center p-2.5 text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue hover:bg-customDarkBlue"
                    }
                    onClick={() => navigate("/dashboard/logs")}
                  >
                    <WindowIcon className="w-6 h-6" />
                  </button>
                ) : null}
                {getPermission &&
                getPermission("VIEW_SUBMISSIONS")?.isGranted ? (
                  <button
                    title="Submissions"
                    className={
                      "items-center bg-customBlue justify-center p-2.5 text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue hover:bg-customDarkBlue"
                    }
                    onClick={() => navigate("/dashboard/submissions")}
                  >
                    <BeakerIcon className="w-6 h-6" />
                  </button>
                ) : null}
              </div>
            </div>*/}
          </div>
        )}
      </div>
    </div>
  );
}
