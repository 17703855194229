import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { IReport } from "../../interfaces/report";
import { API_URL } from "../../../../constants";
import { useToken } from "../misc/useToken";

export const useReports = () => {
  const accessToken = useToken();

  const getAllReports = async (): Promise<IReport[]> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const { data } = await axios.get<IReport[]>(API_URL + "/reports", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error: any) {
      //console.error("Failed to fetch reports:", error);
      toast.error(error.response?.data || "Failed to fetch reports");
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["reports"],
    queryFn: getAllReports,
    select: (data) => data || [],
  });
  return { isLoading, reports: data, error };
};
export default useReports;
