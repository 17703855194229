import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { API_URL } from "../../../../constants";
import { useToken } from "../misc/useToken";
import { ReportFormValues } from "../../../forms/ReportForm";

interface IUseDeleteReportProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

// React Query hook to fetch bikes with access token
export const useCreateReport = (props?: IUseDeleteReportProps) => {
  const { getUser } = useKindeAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const createNewReport = async (report: ReportFormValues) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    const user = getUser?.();
    return await axios.post(
      API_URL + "/reports",
      {
        journalNumber: report.journalNumber,
        date: report.date,
        description: report.description,
        active: report.active,
        bikeReference: report.bikeReference,
        updatedBy: user ? `${user.given_name} ${user.family_name}` : "Unknown",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["post", "report"],
    mutationFn: (report: ReportFormValues) => createNewReport(report),
    onError: (error: any) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error.response?.data || "An error occurred");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reports"] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate("/dashboard/reports");
    },
  });
};

export default useCreateReport;
