import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./modules/layout/layout";
import { ProtectedRoute } from "./modules/auth/ProtectedRoute";
import { MainPage } from "./pages/dashboard/main";
import { QueryClient } from "@tanstack/react-query";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { useMemo } from "react";
//import { HandleCallbackPage } from "./pages/handleCallback";
import { BikesPage } from "./pages/dashboard/bikes";
import { NewBikePage } from "./pages/dashboard/bikes.new";
import { ShowBikePage } from "./pages/dashboard/bikes.$id";
import { ReportsPage } from "./pages/dashboard/reports";
import { NewReportPage } from "./pages/dashboard/reports.new";
import { ShowReportPage } from "./pages/dashboard/reports.$id";
import { UsersPage } from "./pages/dashboard/users";
import NotFound from "./pages/not-found";
import Error404Page from "./pages/dashboard/404";
import { LogOutPage } from "./pages/dashboard/logout";
import { CLIENT_ID, KINDE_DOMAIN } from "./constants";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import WelcomePage from "./pages/welcome";
import { EditReportPage } from "./pages/dashboard/reports.edit.$id";
import ProfilePage from "./pages/dashboard/profile";
import { LogsPage } from "./pages/dashboard/logs";
import { ShowLogsPage } from "./pages/dashboard/logs.$id";
import { Toaster } from "react-hot-toast";
import { ShowUserPage } from "./pages/dashboard/users.$id";
import { ErrorBoundary } from "react-error-boundary";
import Button from "./modules/components/Button";
import NewSubmissionPage from "./pages/dashboard/submissions.new";
import { SubmissionsPage } from "./pages/dashboard/submissions";
import { ShowSubmissionPage } from "./pages/dashboard/submissions.$id";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15, // 15 minutes
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      retry: 6,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  const callback = useMemo(
    () => `${window.location.origin}/dashboard/statistics`,
    []
  );

  return (
    <KindeProvider
      clientId={CLIENT_ID}
      domain={KINDE_DOMAIN}
      redirectUri={callback}
      logoutUri={window.location.origin}
    >
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <ErrorBoundary
          fallbackRender={({ error }) => (
            <div className="flex flex-col items-center justify-center min-h-screen">
              <h1 className="text-4xl font-bold">Error</h1>
              <p>Whoops, something went wrong.</p>
              <p className="text-red-500 italic text-xs mt-1.5">
                {error.message}
              </p>
              <Link to="/dashboard/statistics">
                <Button
                  title="Back to Dashboard"
                  variant="primary"
                  className="mt-2"
                />
              </Link>
            </div>
          )}
        >
          <Toaster />

          <BrowserRouter>
            <Routes>
              <Route index element={<Navigate to="sign-in" />} />
              <Route path={"sign-in"} element={<WelcomePage />} />
              {/*<Route path={"handleCallback"} element={<HandleCallbackPage />} />*/}

              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Navigate to="statistics" />} />
                <Route path="statistics" element={<MainPage />} />

                <Route path="bikes" element={<BikesPage />} />
                <Route path="bikes/new" element={<NewBikePage />} />
                <Route path="bikes/:id" element={<ShowBikePage />} />

                <Route path="reports" element={<ReportsPage />} />
                <Route path="reports/new" element={<NewReportPage />} />
                <Route path="reports/:id" element={<ShowReportPage />} />
                <Route path="reports/edit/:id" element={<EditReportPage />} />

                <Route path="users" element={<UsersPage />} />
                <Route path="users/:id" element={<ShowUserPage />} />

                <Route path="logout" element={<LogOutPage />} />

                <Route path="profile" element={<ProfilePage />} />

                <Route path="submissions" element={<SubmissionsPage />} />
                <Route path="submissions/new" element={<NewSubmissionPage />} />
                <Route
                  path="submissions/:id"
                  element={<ShowSubmissionPage />}
                />

                <Route path="logs" element={<LogsPage />} />
                <Route path="logs/:id" element={<ShowLogsPage />} />

                <Route path="*" element={<Error404Page />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </PersistQueryClientProvider>
    </KindeProvider>
  );
}

export default App;
