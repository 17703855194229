import { Navigate, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import { IReport } from "../../modules/api/interfaces/report";
import Table from "../../modules/components/table/Table";
import useBikes from "../../modules/api/hooks/bikes/useBikes";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";
import BikeForm from "../../modules/forms/BikeForm";

export function ShowBikePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { bikes } = useBikes();
  const bike = bikes?.find((bike) => bike.frameNumber === id);

  if (!bike) {
    return <Navigate to="/dashboard/bikes" />;
  }

  const columns = [
    {
      label: "Journal number",
      accessor: (row: IReport) => row.journalNumber,
      render: (journalNumber: string) => {
        return (
          <button
            className="underline hover:text-customBlue cursor-pointer"
            onClick={() => {
              navigate("/dashboard/reports/" + journalNumber);
            }}
          >
            {journalNumber}
          </button>
        );
      },
    },
    {
      label: "Date",
      accessor: (row: IReport) => row.date,
      render: (date: Date) => {
        return format(new Date(date), "dd-MM-yyyy", {
          locale: da,
        });
      },
    },
    {
      label: "Description",
      accessor: (row: IReport) => row.description,
      render: (description: string) => {
        return description.slice(0, 80) + "...";
      },
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },
    {
      label: "Frame number",
      accessor: (row: IReport) => "SF" + row.bikeReference,
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },
    {
      label: "Active?",
      accessor: (row: IReport) => row.active === true,
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Bike</p>
      </div>
      <BikeForm readOnly bikeData={bike} />
      <h2 className="block mt-8 mb-2 text-gray-900">Bikes report history</h2>
      <Table
        data={bike?.reports || []}
        columns={columns}
        tableActions={{
          deleteFn: (item) => console.log(item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.journalNumber}?`,
        }}
        idAccessor={(row) => row.journalNumber}
        emptyMessage="No reports found for this bike"
        hideViewButton={true}
      />
    </div>
  );
}
