import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../../constants";
import { IBikeForm } from "../../../../pages/dashboard/bikes.new";
import { useToken } from "../misc/useToken";

interface IUseDeleteBikeProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useCreateBike = (props?: IUseDeleteBikeProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const createNewBike = async (bike: IBikeForm) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    return await axios.post(
      API_URL + "/bikes",
      {
        frameNumber: bike.frameNumber,
        bikeType: bike.bikeType,
        color: bike.color,
        trackerId: bike.trackerId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["post", "bike"],
    mutationFn: (bike: IBikeForm) => createNewBike(bike),
    onError: (error: any) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error.response?.data || "An error occurred");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["bikes"] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate("/dashboard/bikes");
    },
  });
};
