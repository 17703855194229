import { Navigate, useParams } from "react-router-dom";
import InputField from "../../modules/components/form/InputField";
import CollapsibleBlock from "../../modules/components/CollapsibleBlock";
import useLogs from "../../modules/api/hooks/misc/useLogs";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";

export function ShowLogsPage() {
  const { id } = useParams();
  const { logs } = useLogs();
  const log = logs?.find((log) => log.requestId === id);

  if (!log) {
    return <Navigate to="/dashboard/logs" />;
  }

  const { requestId, requestData, headers, ...requestStripped } = log;

  let headersData = {};
  let request = {};

  if (log.headers) {
    try {
      headersData =
        typeof log.headers === "string" ? JSON.parse(log.headers) : log.headers;
    } catch (error) {
      console.error("Failed to parse headers:", error);
    }
  }

  if (log.requestData) {
    try {
      request =
        typeof log.requestData === "string"
          ? JSON.parse(log.requestData)
          : log.requestData;
    } catch (error) {
      console.error("Failed to parse request data:", error);
    }
  }

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Log</p>
      </div>
      <div className="gap-4 sm:gap-6 mt-4">
        <InputField
          label="Request ID"
          name="request-id"
          type="text"
          value={log?.requestId}
          onChange={() => {}}
          readonly
          className="break-all"
        />
        <div className="space-y-4 mt-6">
          <CollapsibleBlock title="General">
            <pre className="text-sm whitespace-pre-wrap break-words overflow-auto max-w-full p-4">
              {JSON.stringify(requestStripped, null, 2)}
            </pre>
          </CollapsibleBlock>
          {log.requestData && (
            <CollapsibleBlock title="Request Data">
              <pre className="text-sm whitespace-pre-wrap break-words overflow-auto max-w-full p-4">
                {JSON.stringify(request, null, 2)}
              </pre>
            </CollapsibleBlock>
          )}
          <CollapsibleBlock title="Headers">
            <pre className="text-sm whitespace-pre-wrap break-words overflow-auto max-w-full p-4">
              {JSON.stringify(headersData, null, 2)}
            </pre>
          </CollapsibleBlock>
        </div>
      </div>
    </div>
  );
}
