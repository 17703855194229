import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../../../../constants";
import { IReport } from "../../interfaces/report";
import { useToken } from "../misc/useToken";

interface IUseDeleteReportProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteReport = (props?: IUseDeleteReportProps) => {
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const deleteReportWithJournalNumber = async (journalNumber: string) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }
    return await axios.delete(API_URL + `/reports/${journalNumber}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return useMutation({
    mutationKey: ["delete", "report"],
    mutationFn: (report: IReport) =>
      deleteReportWithJournalNumber(report.journalNumber),
    onError: (error: Error) => {
      console.error(error);
      props?.onError?.();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reports"] });
      props?.onSuccess?.();
    },
  });
};
