import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { ILoggingModule } from "../../interfaces/loggingModule";
import { API_URL } from "../../../../constants";
import { useToken } from "./useToken";

// React Query hook to fetch logs with access token
export const useLogs = () => {
  const accessToken = useToken();

  const fetchLogs = async (): Promise<ILoggingModule[]> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const { data } = await axios.get<ILoggingModule[]>(API_URL + "/logs", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error: any) {
      toast.error(error.response?.data || "Failed to fetch logs");
      //console.error("Failed to fetch logs:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["logs"],
    queryFn: fetchLogs,
    select: (data) => data || [],
  });

  return { isLoading, logs: data, error };
};

export default useLogs;
