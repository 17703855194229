import { Link, useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Table from "../../modules/components/table/Table";
import { useEffect } from "react";
import SearchComponent from "../../modules/components/SearchComponent";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";
import {
  ISubmission,
  SubmissionType,
} from "../../modules/api/interfaces/submissions";
import Button from "../../modules/components/Button";
import useSubmissions from "../../modules/api/hooks/submissions/useSubmissions";

export function SubmissionsPage() {
  const { submissions, isLoading } = useSubmissions();
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission("VIEW_SUBMISSIONS")?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  const columns = [
    {
      label: "ID",
      accessor: (row: ISubmission) => row.submissionId.toString(),
    },
    {
      label: "Type",
      accessor: (row: ISubmission) => SubmissionType[row.type],
      render: (type: SubmissionType) => (
        <span
          className={`px-2 w-16 text-center rounded inline-block ${
            Number(SubmissionType[type]) === 0
              ? "bg-cyan-200 text-cyan-800"
              : "bg-indigo-200 text-indigo-800"
          }`}
        >
          {type}
        </span>
      ),
    },
    {
      label: "Title",
      accessor: (row: ISubmission) => row.title,
      render: (title: string) => {
        return title.length > 15 ? title.slice(0, 15) + "..." : title;
      },
    },
    {
      label: "Summary",
      accessor: (row: ISubmission) => row.description,
      render: (description: string) => {
        return description.slice(0, 10) + "...";
      },
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Open?",
      accessor: (row: ISubmission) => row.open === true,
      render: (suspended: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            suspended ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {suspended ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <p className="text-2xl tracking-wide font-medium h-10">Submissions</p>
        <div className="mt-4 sm:mt-0 w-full sm:w-auto sm:flex-shrink-0">
          <Link to="./new">
            <Button
              title="Create new"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-row mt-4 gap-4">
        <SearchComponent
          placeholder="Search by ID..."
          data={[]}
          inputMode="text"
          filterAttribute="submissionId"
          onChange={(data) => {
            //setSearchData(data);
          }}
        />
      </div>
      <Table
        data={
          (submissions || [])
            .sort(
              (a: ISubmission, b: ISubmission) =>
                Number(b.open) - Number(a.open)
            )
            .filter((item: ISubmission) => item) || []
        }
        columns={columns}
        tableActions={{
          deleteFn: (item) => console.log("Deleting", item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.submissionId}?`,
        }}
        idAccessor={(row) => row.submissionId}
        isLoading={isLoading}
        emptyMessage="No submissions found"
      />
    </div>
  );
}
