import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {} from "@kinde-oss/kinde-auth-react";
import toast from "react-hot-toast";
import { API_URL } from "../../../../constants";
import { ISubmission } from "../../interfaces/submissions";
import { useToken } from "../misc/useToken";

// React Query hook to fetch submisisons with access token
export const useSubmissions = () => {
  const accessToken = useToken();

  const fetchSubmissions = async (): Promise<ISubmission[]> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }
    try {
      const { data } = await axios.get<ISubmission[]>(
        API_URL + "/submissions",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      toast.error(error.response?.data || "Failed to fetch submissions");
      //console.error("Failed to fetch submisisons:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["submissions"],
    queryFn: fetchSubmissions,
    select: (data) => data || [],
  });

  return { isLoading, submissions: data, error };
};

export default useSubmissions;
