import { Link } from "react-router-dom";
import {
  BikeType,
  hexToBikeColorMap,
  IBike,
} from "../../modules/api/interfaces/bike";
import Table from "../../modules/components/table/Table";
import Button from "../../modules/components/Button";
import SearchComponent from "../../modules/components/SearchComponent";
import { useDeleteBike } from "../../modules/api/hooks/bikes/useDeleteBike";
import toast from "react-hot-toast";
import useBikes from "../../modules/api/hooks/bikes/useBikes";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";

export function BikesPage() {
  const { bikes, isLoading } = useBikes();
  // const [searchData, setSearchData] = useState<IBike[] | undefined>(bikes);
  // Search functionality is disabled for now

  const { mutate: deleteBike } = useDeleteBike({
    onSuccess: () => {
      toast.success("Bike deleted successfully!");
    },
    onError: (error: { response: { data: string } }) => {
      toast.error(error.response.data);
    },
  });

  const handleDelete = (bike: IBike) => {
    deleteBike(bike);
  };

  const columns = [
    {
      label: "Frame number",
      accessor: (row: IBike) => "SF" + row.frameNumber,
    },
    {
      label: "Color",
      accessor: (row: IBike) => row.color,
      render: (color: string) => (
        <div className="inline ml-2 sm:ml-0">
          <span className="mr-0 sm:mr-2" style={{ color: color }}>
            ⬤
          </span>
          <span className="hidden sm:table-cell">
            {hexToBikeColorMap[color]}
          </span>
        </div>
      ),
    },
    {
      label: "Type",
      accessor: (row: IBike) => BikeType[row.bikeType],
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
    {
      label: "Missing?",
      accessor: (row: IBike) => row.reports?.some((report) => report.active),
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
      classNameCell: "text-center sm:text-left",
      classNameHeader: "text-center sm:text-left",
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <p className="text-2xl tracking-wide font-medium h-10">Bikes</p>
        <div className="mt-4 sm:mt-0 w-full sm:w-auto sm:flex-shrink-0">
          <Link to="./new">
            <Button
              title="Create bike"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-row mt-4 gap-4">
        <SearchComponent
          placeholder="Search by frame number..."
          data={bikes || []}
          inputMode="numeric"
          filterAttribute="frameNumber"
          onChange={() => {}}
        />
      </div>
      <Table
        data={
          (bikes || [])
            .sort((a: IBike, b: IBike) => {
              const aActive = a.reports?.some((report: any) => report.active);
              const bActive = b.reports?.some((report: any) => report.active);
              return Number(bActive) - Number(aActive);
            })
            .filter((item: IBike) => item) || []
        }
        columns={columns}
        isLoading={isLoading}
        tableActions={{
          deleteFn: (item) => handleDelete(item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete bike - ${item.frameNumber}?`,
        }}
        idAccessor={(row) => row.frameNumber}
        emptyMessage="No bikes found"
        editPermission="EDIT_BIKE"
        deletePermission="DELETE_BIKE"
      />
    </div>
  );
}

export default BikesPage;
