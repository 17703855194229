import { FC } from "react";
import SmallLoadingSpinner from "./SmallLoadingSpinner";

interface IButtonprops {
  title?: string;
  variant: "primary" | "secondary" | "icon";
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  icon?: React.ComponentType<React.ComponentProps<"svg">>;
  onClick?: () => void;
}

const Button: FC<IButtonprops> = ({
  title,
  variant,
  disabled,
  isLoading,
  icon: Icon,
  className,
  onClick,
}) => {
  let buttonVariants = {
    primary:
      "disabled:opacity-70 inline-block rounded-lg bg-customBlue text-slate-50 text-sm font-medium px-4 py-2.5 hover:bg-customDarkBlue active:bg-customDarkBlue active:shadow-md",
    secondary:
      "disabled:opacity-70 inline-block rounded-lg bg-slate-100 text-slate-500 text-sm font-medium px-4 py-2.5 hover:bg-slate-200 active:bg-slate-200 active:shadow-md",
    icon: "disabled:opacity-70 inline-flex items-center justify-center rounded-lg bg-white text-slate-500 text-sm font-medium p-2.5 hover:bg-slate-100 active:bg-slate-200",
  };
  return (
    <button
      onClick={onClick}
      className={`${buttonVariants[variant]} ${className} relative`}
      disabled={disabled}
      style={{ minWidth: "130px", minHeight: "40px" }}
    >
      {isLoading ? (
        <span className="aboslute inset-0 flex items-center justify-center">
          <SmallLoadingSpinner />
        </span>
      ) : null}
      {!isLoading && Icon && <Icon className="h-5 w-5" />}
      <span className={isLoading ? "hidden" : ""}>{title}</span>
    </button>
  );
};

export default Button;
