import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BikeType, IBike } from "../api/interfaces/bike";
import FormSelect from "../components/form/FormSelect";
import BikeColorDropdown from "../components/BikeColorPicker";
import Button from "../components/Button";
import FormInput from "../components/form/FormInput";

// Validation schema using Yup
const bikeSchema = yup.object().shape({
  frameNumber: yup
    .string()
    .matches(/^\d+$/, "Frame number must contain only numbers")
    .min(5, "Frame number must be at least 5 characters long")
    .required("Frame number is required"),
  trackerId: yup
    .string()
    .optional()
    .when("bikeType", {
      is: (value: any) =>
        value === BikeType.Power1 || value === BikeType.Power7,
      then: (schema) =>
        schema.nullable("Tracker ID is required for Power1 or Power7"), // Change to .required later
      otherwise: (schema) => schema.notRequired(),
    }),
  bikeType: yup
    .number()
    .oneOf(
      Object.values(BikeType).filter(
        (value) => typeof value === "number" && value !== BikeType.Select
      ) as number[],
      "Invalid bike type"
    )
    .required("Bike type is required"),
  color: yup.string().required("Color is required"),
});

interface BikeFormProps {
  bikeData?: IBike;
  readOnly?: boolean;
  buttonPlaceholder?: string;
  onSubmit?: (data: IBike) => void;
}

const BikeForm: React.FC<BikeFormProps> = ({
  bikeData,
  readOnly = false,
  buttonPlaceholder = "Submit",
  onSubmit = () => {},
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IBike>({
    defaultValues: bikeData || {
      frameNumber: "",
      bikeType: BikeType.Select,
      color: "",
      trackerId: "",
    },
    resolver: yupResolver(bikeSchema),
  });

  const bikeType = useWatch({
    control,
    name: "bikeType",
  }) as BikeType;

  const bikeTypeOptions = Object.entries(BikeType)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ label: key, value }));

  return (
    <form>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 mt-4">
        <FormInput
          type="text"
          name="frameNumber"
          control={control}
          label="Frame number"
          disabled={readOnly}
          placeholder="Frame Number"
        />
        <FormSelect
          name="bikeType"
          control={control}
          label="Type"
          options={bikeTypeOptions}
          disabled={readOnly}
        />
        <BikeColorDropdown
          name="color"
          control={control}
          bikeType={bikeType}
          label="Color"
          disabled={readOnly}
        />
        {/* eslint-disable-next-line */}
        {!(bikeType == BikeType.Power1 || bikeType == BikeType.Power7) ? (
          <div className="col-2" />
        ) : (
          <FormInput
            type="text"
            name="trackerId"
            control={control}
            label="Tracker ID"
            disabled={readOnly}
            placeholder="Tracker ID for E-bike"
          />
        )}
      </div>

      {!readOnly && (
        <Button
          onClick={handleSubmit(onSubmit)}
          title={buttonPlaceholder}
          variant="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          className="mt-4 w-full"
        />
      )}
    </form>
  );
};

export default BikeForm;
