import { useNavigate } from "react-router-dom";
import { IUser } from "../../modules/api/interfaces/user";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Table from "../../modules/components/table/Table";
import { useEffect } from "react";
import useUsers from "../../modules/api/hooks/misc/useUsers";
import SearchComponent from "../../modules/components/SearchComponent";
import { Breadcrumbs } from "../../modules/layout/Breadcrumbs";

export function UsersPage() {
  const { users, isLoading } = useUsers();
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission("VIEW_USERS")?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  const columns = [
    {
      label: "ID",
      accessor: (row: IUser) => row.userId,
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Name",
      accessor: (row: IUser) => row.name,
    },
    {
      label: "Email",
      accessor: (row: IUser) => row.email,
      classNameHeader: "hidden xl:table-cell",
      classNameCell: "hidden xl:table-cell",
    },
    {
      label: "Suspened?",
      accessor: (row: IUser) => row.isSuspended === true,
      render: (suspended: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            suspended ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {suspended ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <p className="text-2xl tracking-wide font-medium h-10">Users</p>
      </div>
      <div className="flex flex-row mt-4 gap-4">
        <SearchComponent
          placeholder="Search by name..."
          data={[]}
          inputMode="text"
          filterAttribute="email"
          onChange={(data) => {
            //setSearchData(data);
          }}
        />
      </div>
      <Table
        data={users || []}
        columns={columns}
        tableActions={{
          deleteFn: (item) => console.log("Deleting", item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.name}?`,
        }}
        idAccessor={(row) => row.userId}
        isLoading={isLoading}
        emptyMessage="No users found"
      />
    </div>
  );
}
